.react-tabs__tab{
    width: 50%;
    font-weight: bold;
    font-size: 20px;
    border-radius: 10px 10px 0 0;
    transition: all 0.4s ease;
    text-align: center;
}

.react-tabs__tab.react-tabs__tab--selected{
    background: #555555;
    font-weight: bold;
    font-size: 20px;
    color: white;
    border-color:black
}
.react-tabs__tab-list{
    border-color:black;
    border-width: 0 0 2px 0 ;
}
@media (max-width: 750px) {
    .react-tabs__tab{
        width: fit-content;
        font-weight: bold;
        font-size: 16px;
        border-radius: 10px 10px 0 0;
        transition: all 0.4s ease;
    }
    .react-tabs__tab.react-tabs__tab--selected{
        background: #555555;
        font-weight: bold;
        font-size: 16px;
        color: white;
        border-color:black
    }
}