@tailwind base;
@tailwind components;
@tailwind utilities;

  body {
  margin: 0;
}

.border-animation::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width .3s;
}

.border-animation:hover::after {
  width: 100%;
}

.border-selected::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
}

.footer-links-animation {
  margin-left: 0;
  margin-right: 0;
  transition: all .3s;
}
.footer-links-animation:hover {
  margin-left: 4px;
  margin-right: -4px;
  transition: all .3s;
}

.animation-img:hover>img {
animation: jump infinite 1s;
}

@keyframes jump {
  0% {
    margin-top:0;
    margin-bottom: 0;
  }
  50% {
    margin-top: -4px;
    margin-bottom: 4px;
  }
  100% {
    margin-top:0;
    margin-bottom: 0;
  }
}


@media screen and (max-width: 1023px) {
  #mobile-menu{
    position: absolute;
    background: white;
    animation: rightToLeft ease-in .5s;
    top:84px;
    z-index: 20;
    right: 0;
    box-shadow: 7px 11px 49px -6px rgba(0,0,0,1);
    -webkit-box-shadow: 7px 11px 49px -6px rgba(0,0,0,1);
    -moz-box-shadow: 7px 11px 49px -6px rgba(0,0,0,1);
  }
}

@keyframes rightToLeft {
  0% {
    right:-100%;
  }
  100% {
    right:0;
  }
}

.payment-container {
  height: 100%;
  justify-content: center;
}
.payment-container > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.payment-container > div > div {
  width: 80% !important;
  margin:0 auto;
}
#buttons-container{
  display: flex !important;
  justify-content: center !important;
}
@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}
.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 1010;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition:
          all 200ms linear,
          margin-right 0ms;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
    transition: none;
  }
}
.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap:after {
  position: absolute;
  font-family: "Unicons";
  content: "\e951";
  text-align: center;
  line-height: 2.3rem;
  font-size: 1.2rem;
  color: #001234;
  left: 0;
  top: 0;
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
    transition: none;
  }
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: #001234;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
    transition: none;
  }
}
@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}
/* width */
.scrollable-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}